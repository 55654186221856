import React from "react"
import { 
    // Link,
    graphql 
} from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

class SangNamGan extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="SangNamGan" />
        <div style={{ position: 'relative', padding: '6rem 1.2rem 4rem', minHeight: '350px', }}>
          <Image 
            style={{ opacity: '.4', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,}}
            fluid={data.backgroundImage.childImageSharp.fluid} 
          />
          <div style={{ maxWidth: '800px', textAlign: 'center', margin: '0 auto', position: 'relative'}}>
            <Title>SangNamGan: Reimaging Community Development in Lao PDR</Title>
            <div style={{ maxWidth: '700px', margin: '0 auto'}}>
              <span style={{fontWeight: '600', }}>Giving villagers a voice in local development initiatives</span>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: '#F9F6F7', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto'}}>
            <SectionHeading>Project Context</SectionHeading>
            <div>
              <p>
                In rural Lao PDR, many communities had dreams of low-budget projects that were in need of a small boost. 
                Project ideas like school libraries or furniture cooperatives were left to squander if no non-profit could intervene to fill the need. Through initial interviews with NGO staff and villagers, I noticed two widely-held beliefs:
              </p>
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', marginTop: '20px'}}>
                <IconContainer>
                  <StyledStripImage style={{width: '150px', margin: '0 auto'}} fluid={data.nomoney.childImageSharp.fluid} />
                  <Accent className="bold color" style={{ margin: '10px auto 0', display: 'block', textAlign: 'center'}}>Lack of funds</Accent>
                  <p style={{color: "rgba(0, 0, 0, 0.8)", fontWeight: 400, fontSize: '16px', textAlign: 'center' }}> When asked about funding, people were quick to explain "none existed".
                  There was no room in the school budget, government budget, or NGO budgets to help fund local initiatives unless they had been outlined years in advance.
                  </p>
                </IconContainer>
                <IconContainer>
                  <StyledStripImage style={{width: '150px', margin: '0 auto'}} fluid={data.confusion.childImageSharp.fluid} />
                  <Accent className="bold color" style={{ margin: '10px auto 0', display: 'block', textAlign: 'center'}}>Lack of agency</Accent>
                  <p style={{color: "rgba(0, 0, 0, 0.8)", fontWeight: 400, fontSize: '16px', textAlign: 'center'}}>The language used to describe these projects was often in a helpless, passive way.  
                    Community leaders were in a state of waiting for non-profits to come to their aid.
                  </p>
                </IconContainer>
              </div>
              <p>
                While the current aid system was certainly helping many villages in need, there were still many more villages with unlocked potential.
              </p>
            </div>
            {/* <p style={{ marginTop: '20px',}}>
              After living and working in Lao PDR for ten months, I discovered a school director and English teacher of Nam Meung Town desperately wanted a secondary school library. 
              I emailed more than 10 education-focused NGOs to solicit their help, but each has its own reason for declining—understaffed, lack of funds, outside of their pre-determined program
              activities. 
            </p>
            <p style={{marginBottom: "0"}}>
              I was shocked: <Accent className="bold" style={{fontStyle: 'italic'}}>How could there be no channel to help these secondary school teachers obtain something as indisputably positive as a library?</Accent> I returned to the town in 2016 to conduct research on ways forward.
            </p> */}
            <ProjectBackground>
              <div><Accent className="bold" style={{ textTransform: 'uppercase'}}>Role</Accent><br/>Researcher, Pilot Director</div>
              <div><Accent className="bold" style={{ textTransform: 'uppercase'}}>Methods</Accent><br/>Interviews, Focus Group, Ethnography</div>
              <div><Accent className="bold" style={{ textTransform: 'uppercase'}}>Date</Accent><br/>August 2016 to October 2016</div>
            </ProjectBackground>
          </div>
        </div>
        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start'}}>
            <StyledStripImage fluid={data.bambooHut.childImageSharp.fluid} />
            <StyledStripImage fluid={data.circle.childImageSharp.fluid} />
            <StyledStripImage fluid={data.mengschool.childImageSharp.fluid} />
            <StyledStripImage fluid={data.smallkidshandsup.childImageSharp.fluid} />
        </div>

        <div style={{ backgroundColor: '#fff', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto'}}>
            <SectionHeading>Defining the Problem</SectionHeading>
        
            <SectionSubheading style={{marginBottom: '20px'}}>The Design Question</SectionSubheading>
            <div>
              <p style={{color: '#f9a83d', fontSize: '30px', lineHeight: 1.3, borderLeft: '3px solid', paddingLeft: '30px', marginLeft: '40px'}}>
                How might we empower communities to drive their own development and fund local projects?
              </p>
            </div>
            <SectionSubheading style={{marginBottom: '20px'}}>My Role</SectionSubheading>
            <p>
              I led the <Accent className="color bold">qualitative research and ideation phases</Accent> of SangNamGan between August 2016 and October 2016. 
         
              I continued working on the project during the implementation phase from October 2016 to October 2017.
            </p>
          </div>
          <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', maxWidth: '790px', margin: '0 auto'}}> 
            <StyledTopicImage fluid={data.studentsonsteps.childImageSharp.fluid} />
            <StyledTopicImage fluid={data.kidsinclassroom.childImageSharp.fluid} />
          </div>
        </div>
        <div style={{ backgroundColor: '#F9F6F7', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto', }}>
            <SectionHeading>A Glimpse of the Process</SectionHeading>
            <SectionSubheading style={{ margin: '0 0 10px',}}>Formulating Research Questions</SectionSubheading>

            <p>
              I formulated research questions to investigate the two widely held beliefs of <Accent className="color bold">lack of funds</Accent> and <Accent className="color bold">lack of agency</Accent>. My research questions included:
              <ul style={{ margin: '0 0 0 2rem', listStyle: 'none'}}>
                <li style={{ marginTop: '10px'}}><Accent className="bold">Question 1: </Accent>How do non-profits invovle communities in development initiatives?</li>
                <li><Accent className="bold">Question 2:</Accent> What is the current relationship between community leaders, non-profit workers and government officials?</li>
                <li><Accent className="bold">Question 3: </Accent> Where are villagers spending their money? </li>
              </ul>
            </p>
            <SectionSubheading style={{ marginBottom: '10px',}}>Timeline Overview</SectionSubheading>

          </div>
          <Image style={{ maxWidth: '1250px', margin: '0 auto'}} fluid={data.timeline.childImageSharp.fluid} />

        </div>

        <div style={{ backgroundColor: '#FFF', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto 50px', }}>
            <SectionHeading>Discovery</SectionHeading>
            <SectionSubheading style={{ marginTop: 0}}>Landscape Analysis: <span style={{ fontWeight: 400, fontStyle: 'italic' }}>How are existing organizations engaging with communities and setting development goals?</span></SectionSubheading>
                {/* <p>
                  I identified key players in Lao PDR's non-profit space and classified them by relevant characteristics (type, funding model, type of organization, target beneficiary, etc.) 
                </p> */}
                <p>
                  I analyzed 4 international non-profits (Plan International, Care, Room To Read, Pencils of Promise) 
                  and 3 grassroots organizations (Jai Lao, Give Children a Choice and Bokeo Development Fund) working in Lao PDR at the time. 
                </p>
                <p style={{ marginBottom: '5px'}}>
                  My objective was to understand the role community leaders were playing in defining development initiatives. Which actors were defining development goals? How were communities engaging in projects?
                </p>
                <Image fluid={data.landscapeanalysis.childImageSharp.fluid} />
                <p>
                  As you can see above, the non-profit landscape was split mostly between organizations operating in 
                  Quandrants II & IV. 
                </p>
                <p>
                  My few research points included:
                  <ol style={{ color: "#f9a83d", fontWeight: 700, marginTop: '5px', paddingLeft: '15px'}}>
                    <li style={{ marginBottom: '5px'}}>
                      International NGOs = Top-Down Development: <span style={{ color: 'hsla(0,0%,0%,0.8)', fontWeight: 400}}>International non-profits often had five-year targets set in conjunction with the national government and little wiggle room to adapt to local requests.</span>
                    </li>
                    <li style={{ marginBottom: '5px'}}>
                      Community Contributions Gaining Traction: <span style={{ color: 'hsla(0,0%,0%,0.8)', fontWeight: 400}}>Pencils of Promise, Bokeo Development Fund and Give Children a Voice were successfully asking and receiving community contributions of materials and labor.
                    </span>
                    </li>
                   </ol>
                </p>
                <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', maxWidth: '790px', margin: '0 auto'}}> 
              <StyledTopicImage fluid={data.villagetownhall.childImageSharp.fluid} />
              <StyledTopicImage fluid={data.ethnoparents.childImageSharp.fluid} />
              </div>
            <SectionSubheading>In-depth Interviews: <span style={{ fontWeight: 400, fontStyle: 'italic' }}>How do non-profits workers, government officials and community leaders view each other?</span>
            </SectionSubheading>
            <p>
              I held in-depth interviews with 5 village authorities, 10 Lao non-profit workers, and 5 government officials to understand the dynamics between the different groups. 
            </p>
           
          </div>
        </div>
        <StyledMethodImage fluid={data.underroof.childImageSharp.fluid} />
        <div style={{ backgroundColor: '#F9F6F7', padding: '1rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto 50px', }}>
            {/* <SectionHeading style={{ marginBottom: '10px',}}>Studying Spending Habits</SectionHeading> */}
            <SectionSubheading>Ethnographic Field Research: <span style={{ fontWeight: 400, fontStyle: 'italic' }}>What does daily life look like?</span></SectionSubheading>
              <p>
                I immersed myself in Nam Meung village for four weeks, living, working and volunteering in the town.</p>
              <p>The town was rural, located three hours from the provincial capital with 
                  only one local bus traveling between the provincial capital per day. It also had previously worked with NGOs and many community needs, making it great for research.
              </p>
              <p> I became friendly with villagers through passive and active participation, including:
           
                <ul>
                  <li>            
                    Celebrating holidays and attending community events
                  </li>
                  <li>
                    Joining family dinners with friends
                  </li>
                  <li>
                    Helping with weekend chores like rice farming and bamboo collecting
                  </li>
                  <li>
                    Teaching english twice a week to neighborhood children
                  </li>
                </ul>
              </p>
              
              
            </div>
            </div>
            <div style={{ backgroundColor: 'rgb(255, 195, 88)', padding: '4rem 1.2rem',}}>
              <div style={{ maxWidth: '780px', margin: '0 auto 50px', }}>
                <p>
                    I observed how spending habits and money flow in great detail:
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '80%', margin: '0 auto', flexWrap: 'wrap'}}>
                  <div style={{ flex: '0 0 48%', marginBottom: '30px'}}>
                    <Accent className="bold" style={{display: 'block'}}>Rotating savings accounts</Accent> Baby showers, weddings, and small community gatherings were all part of the daily life. At these types of parties, a contribution of 35,000LAK was common and expected.
                  </div>
                  <div style={{ flex: '0 0 48%'}}>
                  <Accent className="bold" style={{display: 'block'}}>Lottery Tickets Worth the Risk</Accent> People were often buying lottery tickets, gambling on a quick win. 
                  </div>
                  <div style={{ flex: '0 0 48%'}}>
                  <Accent className="bold" style={{display: 'block'}}>Events Galore</Accent> Baby showers, weddings, and small community gatherings were all part of the daily life. At these types of parties, a contribution of 35,000LAK was common and expected.
                  </div>
              </div>
            </div>
            <div style={{ backgroundColor: '#F9F6F7', padding: '1rem 1.2rem 4rem',}}>
              <div style={{ maxWidth: '780px', margin: '0 auto 50px', }}>
            
              <SectionSubheading>Secondary Research on Villager Income: <span style={{ fontWeight: 400, fontStyle: 'italic' }}>What is the average villager income in rural Lao PDR?</span></SectionSubheading>
              <p>
                There is limited written material on rural household incomes in Laos, and searches to find breakdowns of monthly expenditures were unsuccessful. 
                Small towns often employ a number of government staff, who make an average income of around $100 dollars/month. 
                This matched with what I had heard orally when interviewing government officials in Meung Town. 
             </p>
              <SectionSubheading>Literature Review</SectionSubheading>
              <p style={{ marginBottom: 0 }}>
                I reviewed the literature of behavioral economics to better understand the spending habits and conflicting motivations of people investing in long-term goals.
              </p> 
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px'}}>
                  <div>
                    <Image style={{width: '150px', margin: '0 auto'}} fluid={data.stress.childImageSharp.fluid} />
                  </div>
                  <ResearchIconContainer>
                    <Accent className="bold color" style={{ display: 'block' }}>Scarcity Mindset</Accent>
                    <p style={{color: "rgba(0, 0, 0, 0.8)", fontWeight: 400, fontSize: '16px' }}>
                      A pioneer field in behavioral and social psychology argues that the 
                      feeling of scarcity affects humans’ decision-making. 
                      While this scarcity mindset has positive benefits (it
                      increases focus in the short-term), the long-run effects can be crippling. 
                    </p>
                  </ResearchIconContainer>
              </div>
              <p>
                With regards to financial decisions, the extra anxiety of scarcity saps people’s 
                brainpower and willpower, causing people to make distracted or
                impulsive decisions and focus on short-term instead of long-term needs.
              </p>
              <div style={{ border: '4px solid #f9a83d', padding: '40px'}}>
                <p>The psychology of scarcity explained a great deal about the spending habits I observed in my ethnographic research.
               </p>
              </div>
              <p>
              </p>
            </div>
        </div>
        <div style={{ backgroundColor: '#FFF', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto 50px', }}>
            <SectionHeading>Ideation</SectionHeading>
            <p>
              I began to synthesize the data. My research had unconvered two insights:
              <ol>
                <li style={{ marginBottom: '5px'}}>
                  Asking communities for contributions was a great way to ensure communities were engaged in the process. 
                </li>
                <li style={{ marginBottom: '5px'}}>
                  Communities had disposable income that could be directed towards the implementation of a community project - the issue was how to motivate users to invest in long-term projects over short-term needs. 
                </li>
              </ol>
            </p>
            <SectionSubheading>Increasing Motivation Through Loss Aversion</SectionSubheading>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px'}}>
                  <div style={{ marginRight: '20px'}}>
                    <Image style={{width: '150px', margin: '0 auto'}} fluid={data.moneyloss.childImageSharp.fluid} />
                  </div>
                  <LossIconContainer style={{marginTop: '15px'}}>
                    <Accent className="bold color" style={{ display: 'block'}}>Loss Aversion</Accent>
                    <p style={{color: "rgba(0, 0, 0, 0.8)", fontWeight: 400, fontSize: '16px'}}>
                      Loss aversion suggests that for individuals the pain of losing is psychologically twice as powerful as the pleasure of gaining.
                    </p>
                  </LossIconContainer>
              </div>
          </div>
        </div>  
        <div style={{ backgroundColor: '#F9F6F7', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto 50px', }}>
            <SectionHeading>Solution</SectionHeading>
            <p>To be expanded on.</p>

            <SectionSubheading>Parent Focus Group: <span style={{ fontWeight: 400, fontStyle: 'italic' }}>How do villagers respond to the idea of funding 50% of projects?</span></SectionSubheading>
            <p>I led a focus group of mothers to determine what they thought of communities funding 50% of a project.
              
              Would say 75,000LAK (~$9.25) be a feasible contribution for each of the 200
              households in Nam Meung Village and Meng Town? The responses were
              revealing.
            </p>
            <StyledImage style={{ flex: '0 0 80%'}} fluid={data.parentgroup.childImageSharp.fluid} />
            <ol style={{ marginTop: '5px', paddingLeft: '15px'}}>
              <li>On-board, but concerned about villager who rely on subsistence farming.</li>
              <li>Extended further.</li>
            </ol>         
            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', maxWidth: '790px', margin: '0 auto'}}> 
          </div>
          </div>
        </div> 
        <div style={{ backgroundColor: '#FFF', padding: '4rem 1.2rem',}}>
          <div style={{ maxWidth: '780px', margin: '0 auto'}}>
            <SectionHeading>Outcomes</SectionHeading>
            <p>
              The solution was proposed to multiple non-profits and foundations and <StyledLink target="_blank" rel="noopener noreferrer"  href="https://jailao.org">Jai Lao Foundation</StyledLink> agreed to fund a 12-month pilot. The pilot launched in August 2017 and ended in October 2018.
            </p>
            <SectionSubheading>Measurements of Success</SectionSubheading>

            <p>
                The secondary school library was the first project under SangNamGan.</p>
            <p>
                Word spread and soon other community leaders began reaching out about potential projects. Future iterations of the concept continued to flourish - with adjustments made to allow communities to contribute labor and materials in replace of cash.
            </p>
            
            <p>
               For more details on outcomes, see the website <StyledLink target="_blank" rel="noopener noreferrer" rel="noopener noreferrer" href='https://www.sangnamgan.com'>here</StyledLink>.
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

const Title = styled.h1`
  font-size: 2.4rem;
  margin-bottom: 20px;

  @media (min-width: 769px) {
    font-size: 3rem;
  }
`;

const SectionHeading = styled.h2`
  text-transform: none;
  font-size: 1.4rem;
  text-align: left;

  @media (min-width: 769px) {
    font-size: 1.6rem;
  }
`;

const SectionSubheading = styled.h3`
  margin-top: 70px;
`

const Accent = styled.span` 
  &.bold {
    font-weight: 600;
  }

  &.color {
    color: #f9a83d;
  }

  .break {
    word-break: break-all;
  }

  .italic {
    font-style: italic;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  margin: 1.2rem auto; 

  @media (min-width: 769px) {
     width: 80%;
  }
`;

const StyledTopicImage = styled(Image)`
  flex: 0 0 100%;
  margin: 15px auto; 

  :last-child {
    margin-bottom: 0;
  }

  @media (min-width: 769px) {
     flex-basis: 48%;
  }
`;

const IconContainer = styled.div`
  flex: 0 0 100%;

  @media (min-width: 769px) {
     flex-basis: 40%;
  }
`;

const ResearchIconContainer = styled.div`
  flex: 0 0 80%;
  text-align: center;

  @media (min-width: 769px) {
    flex-basis: 60%;
    text-align: left;
    margin-left: 30px;
  }
`;

const LossIconContainer = styled.div`
  flex: 0 0 80%;
  text-align: center;
  margin-top: 15px;

  @media (min-width: 769px) {
    flex-basis: 60%;
    text-align: left;
    margin: 0 0 0 30px;
  }
`;

const StyledStripImage = styled(Image)`
  flex: 0 0 50%;

  @media (min-width: 769px) {
     flex-basis: 25%;
  }
`;

const MethodContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledMethodImage = styled(Image)`
  flex: 0 1 100%;
  max-height: 500px;
`;

const Method = styled.div`
  flex: 0 1 100%;
`;

const StyledLink = styled.a`
  font-weight: 600;
  box-shadow: none;
  text-decoration: none;
  color: #000;
  border-bottom: 3px solid #000;

  :hover {
    border-bottom: 3px solid #f9a83d;
    color: #f9a83d;
  }
`;

const ProjectBackground = styled.div`
  margin-top: 40px;
  padding: 32px;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.1);
  color: rgba(92, 92, 92, 0.9);
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  grid-column-gap: 30px;

  @media (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
 }
`;

export default SangNamGan

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    backgroundImage: file(absolutePath: { regex: "/sangnamgan-outsideschool.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bambooHut: file(absolutePath: { regex: "/sangnamgan-bambooHut.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    circle: file(absolutePath: { regex: "/circle.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mengschool: file(absolutePath: { regex: "/sangnamgan-school.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HHstudents: file(absolutePath: { regex: "/sangnamgan-students.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    villagetownhall: file(absolutePath: { regex: "/sangnamgan-process-hats.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    underroof: file(absolutePath: { regex: "/sangnamgan-underroof.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kidsinclassroom: file(absolutePath: { regex: "/sangnamgan-process-kids.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    parentgroup: file(absolutePath: { regex: "/sangnamgan-process.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    childrenbikes: file(absolutePath: { regex: "/sangnamgan-bikes.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    studentsonsteps: file(absolutePath: { regex: "/sangnamgan-students.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smallkidshandsup: file(absolutePath: { regex: "/sangnamgan-school-hands.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ethnoparents: file(absolutePath: { regex: "/APPR_First_parents.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    confusion: file(absolutePath: { regex: "/confusion.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nomoney: file(absolutePath: { regex: "/no-money.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    moneyloss: file(absolutePath: { regex: "/moneyloss.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stress: file(absolutePath: { regex: "/stress.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    landscapeanalysis: file(absolutePath: { regex: "/CompAnalysis.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    timeline: file(absolutePath: { regex: "/Timeline3.png/" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`